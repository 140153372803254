import React from "react";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import { Container } from "reactstrap";
import SEO from "@components/seo";
// import Particles from "react-particles-js"
// import marked from "marked"
// import placeHolderImg from "@images/placeholderBG.jpg"
// import "@components/blog.css"

const termsContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
  backgroundColor: "#011933",
};

// const params = {
//   particles: {
//     number: {
//       value: 20,
//       density: {
//         enable: false,
//       },
//     },
//     size: {
//       value: 10,
//       random: true,
//       anim: {
//         speed: 4,
//         size_min: 0.3,
//       },
//     },
//     color: {
//       value: "#5526fd",
//     },
//     line_linked: {
//       enable: false,
//     },
//     move: {
//       random: true,
//       speed: 1,
//       direction: "top",
//       out_mode: "out",
//     },
//   },
//   interactivity: {
//     events: {
//       onhover: {
//         enable: true,
//         mode: "bubble",
//       },
//       onclick: {
//         enable: true,
//         mode: "repulse",
//       },
//     },
//     modes: {
//       bubble: {
//         distance: 250,
//         duration: 2,
//         size: 0,
//         opacity: 0,
//       },
//       repulse: {
//         distance: 400,
//         duration: 4,
//       },
//     },
//   },
// };

const CaseStudyTemplate = ({ data, location }) => {
  const { post } = data;
  const wonLeads = data.allSanityLeads.edges;

  console.log("singleposts", wonLeads);
  return (
    <Layout location={location}>
      <SEO title={post.title} />
      <div style={termsContent}>
        {/* <Particles
          params={{ ...params }}
          style={{
            width: "100%",
            position: "fixed",
            height: "100%",
            top: 0,
          }}
        /> */}
        <Container className="headline-section">
          <div className="row py-5">
            <div className="col-md-8 mb-4">
              <div className="inner-study">
                <div className="mb-5">
                  <h1 className="font-weight-bold text-light-blue m-0">
                    {post.title}
                  </h1>
                  <h4 className="text-white font-weight-normal">
                    <span className="text-white-50">from</span>{" "}
                    {post.logo.title}
                  </h4>
                </div>
                <img
                  src={post.graphImage.asset.fluid.src}
                  alt="chart"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <img
                  src={post.logo.logo.asset.fixed.src}
                  className="img-fluid casestudy-logo d-block mx-auto mb-4"
                  alt={post.logo.title}
                  width={200}
                />

                <img
                  alt={post.title}
                  className="round-image-shadow shadow-dark mb-3 img-fluid"
                  src={post.headshots[0].image.asset.fixed.src}
                  width={200}
                  height={200}
                />

                <div className="pt-2" style={{ lineHeight: "1.5" }}>
                  <h4 className="my-1 text-white">{post.state1}</h4>
                  <p className="text-white mb-0">and</p>
                  <h4 className="my-1 text-white">{post.state2}</h4>
                  <p className="text-white mb-0">in</p>
                  <h4 className="my-1 text-white">{post.timeFrame}</h4>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <section className="component bg-white" style={{ padding: "100px 0" }}>
          <div className="container">
            <ul className="list-unstyled the-content d-flex justify-content-center flex-wrap align-items-center mb-0">
              {data.allSanityLeads.edges.map(({ node }) => (
                <li
                  className="mx-4 my-3 client-logo"
                  data-sal="slide-up"
                  data-sal-delay={300}
                  data-sal-easing="ease"
                >
                  <img
                    width="120px"
                    src={node.logo.asset.fixed.src}
                    alt={node.title}
                  />
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CaseStudyTemplate;

export const caseStudySingle = graphql`
  query caseStudySingle($id: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    post: sanityCaseStudy(id: { eq: $id }) {
      id
      title
      logo {
        title
        logo {
          asset {
            id
            fixed(width: 200) {
              src
            }
          }
        }
      }
      timeFrame
      state2
      state1
      headshots {
        id
        image {
          asset {
            id
            fixed(width: 200, height: 200) {
              src
            }
          }
        }
      }
      graphImage {
        asset {
          fluid {
            src
          }
        }
      }
    }
    allSanityLeads(
      filter: { publishArea: { eq: "leads won" } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          logo {
            alt
            asset {
              id
              fixed(width: 120) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
